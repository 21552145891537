.help-content{
    width:100vw;
    height:100vh;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left:30%;
}

.content-area-help{
    height:600px;
    width:700px;
    border:1px solid black;
    background-color: #ebd461;
    border-radius: 0px 10px 10px 10px;
    font-size: 1.4rem;
}

.content-area-help h2{
    margin-top: 20px;
    margin-left:30%;
}

.conten-area-help ul,p{
    margin-left: 10px;
}