*{
  box-sizing: border-box;
  margin: 0;
  font-family: 'Arial, Helvetica, sans-serif';
  text-decoration: none;
}

.curriculum-content {
  width: 100%; /* set the width of the main content area */
  padding: 20px; /* add padding to the content area */
}

.sidebar a{
  width:100%;
}

.sidebar h2{
  margin-left: 90px;
}

.sidebar-btn {
  margin-bottom: 20px;
}

.sidebar-btn-1{
cursor: pointer;
overflow: visible;
}

.sidebar-btn-img{
  margin-left: 50px;
}

.sidebar-button-icon {
  margin-top: 8px;
}

.courses-sidebar {
  float: left;
  border-right: 2px solid black;
  width: 20%;
  margin-left: 20px;
  margin-right: 0px;
  height: 100vh;
}

.courses-sidebar:border-right {
  height: 200%;
}

.courses-sidebar span {
  font-size: 1.6rem;
}

.courses-sidebar h2 {
  margin-left: 30px;
  margin-bottom: 30px;
}

.courses-sidebar button {
  display: flex;
  flex-direction: column;
  background-color: #ebd461;
  margin-bottom: 40px;
  height: 70px;
  width: 90%;
  border: none;
}

.courses-sidebar button progress {
  margin-top: 2px;
  /* margin-left: 5px; */
  height: 20px;
}

.courses-sidebar button span {
  font-size: 1.5rem;
  /* margin-left: 30px; */
  margin-right: 15px;
}

.button-text{
  display: flex;
  flex-direction: row;
}

.button-text pre{
  width:50%;
  text-align: center;
  overflow: hidden;
}

.sidebar span,pre {
font-size:1.6rem;
}

.button {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  width: 330px;
  height: 70px;
  text-align: left;
  color: black;
  background-color: #ebd461;
  border: none;
  border-radius: 10px;
  margin-bottom: 25px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-top: 10px;
}
.progress-fill {
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease; /* add transition to animate progress bar */
}

.cur-below-nav{
  display: flex;
}

.nav {
    margin-left: 5px;
    width: 95%;
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-top: 5px;
    border-radius: 10px 10px 10px 10px;
    border: 2px solid rgba(0, 0, 0, 0.357);
    z-index: 20;
    height:120px;
  }

  .ullist{
  display:flex;
  align-items:center;
  justify-content: space-between;
  margin-left:350px;
  }

/* li{
    
    display: flex;
    align-self: right;
    margin-left:50px;
    margin-top: 15px;
}

li a{

    text-decoration: none;
    font-weight: normal;
    font-size: 190%;
    color: black;
    margin-right: 30px;
    transition: all 0.3s ease-in-out;
  }

li a:hover {
    border-bottom: 2px solid black;
  } */


  /* .help-button{
    text-decoration: none;
    background-color: #ebd461;
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    width: 200px;
    align-items:center;
    height: 70px;
  }
  
  .help-button-text{
    font-weight: bold;
    font-size:23px;
    margin-left: 13px;
    align-items: center;
    margin-right: auto;
  }

  .help-button:hover {
    background-color: #ebd461d5;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-underline-offset: 0;
  } */
  

  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  .button a {
    background-color: #FCDE6C;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    color: black;
  }

  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    height: 25% ;
    width: 25%;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .logo span {
    color: rgb(0, 0, 0);
    /* font-weight: bold; */
    font-size: 220%;
    /* margin-top: 0%; */
  }
  

  .custom-video {
    width: 80%;
  }
  
  .custom-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: black;
    padding: 10px;
  }
  
  .control-btn {
    width: 30px;
    height: 30px;
    background-color: rgb(162, 26, 26);
    border: none;
    margin-top: 20px;
    outline: none;
    cursor: pointer;
  }
  
  .play-btn:before {
    content: "";
    display: inline-block;
    border-style: solid;
    border-width: 0 10px 0 0;
    border-color: transparent;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .play-btn.playing:before {
    border-width: 0 0 0 10px;
  }
  
  .progress-bar-vid {
    flex: 1;
    height: 5px;
    background-color: white;
    margin: 0 10px;
    position: relative;
  }
  
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: red;
    width: 0%;
  }
  
  .time {
    color: white;
    font-size: 12px;
  }
  
  a.chatbot-link {
    color:black;
  }
