.textbox-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textbox-input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
}

.submit-button {
  padding: 10px;
  background-color: #ebd461;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
