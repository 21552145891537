.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .chatbot-response {
    margin-bottom: 10px;
  }
  
  .chatbot-input {
    width: 80%;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;
    font-size: 14px;
    background-color: #e6e6e6;
  }
  
  .chatbot-send {
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .chatbot-send:hover {
    background-color: #0069d9;
  }
  