* {
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
}

.maincontainer {
    display: block;
}

/* .nav{
    display:flex;
    margin-left:3%;
    align-items:center;
    margin-top:  20px;
}

.nav-left{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-left: 30px;
}

.nav-logo{
    height:80px;
    width:80px;
}

.nav-logo-h3{
   font-size: 1.6rem;
}

.nav-center{
display:flex;
align-items:center;
justify-content: space-between;
margin-left:120px;

}

.nav-center span{
   color:black;
}

.nav-right{
    margin-left:20%;
}

.help-button{
    height:70px;
    padding-top:3px;
    margin-bottom: 20px;
    
}

.help-button span{
    font-size:1.8rem;
} */

.content-btn {
    color: white;
    background-color: #1E4A5D;
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    height: 70px;
    margin-left: 20px;
}

.content-btn-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.content-area {
    float: left;
    width: 75%;
    display: flex;
    flex-direction: column;
    height: 80%;
}

.courses-sidebar:border-right {
    height: 200%;
}

.below-content-button {
    width: 80%;
    margin-left: 5%;
    display: flex;
    height: 100%;
}

.stats {
    height: 400px;
    font-size: 1.7rem;
}

.stats-title {
    margin-left: 25px;
    margin-top: 12px;
}

.my-image {
    height: 60px;
    width: 60px;
    margin-left: 8px;
}

.my-image-2 {
    height: 60px;
    width: 60px;
    margin-left: 3px;
}

.att,
.scr,
.ovp {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.courses-sidebar {
    float: left;
    border-right: 2px solid black;
    width: 20%;
    margin-left: 20px;
    margin-right: 0px;
    height: 100vh;
}

.courses-sidebar span {
    font-size: 1.6rem;
}

.courses-sidebar h2 {
    margin-left: 60px;
    margin-bottom: 30px;
}

.courses-sidebar button {
    display: flex;
    flex-direction: column;
    background-color: #ebd461;
    margin-bottom: 40px;
    height: 70px;
    width: 90%;
    border: none;
}

.courses-sidebar button progress {
    margin-top: 2px;
    /* margin-left: 5px; */
    height: 20px;
}

.courses-sidebar button span {
    font-size: 1.5rem;
    /* margin-left: 30px; */
    margin-right: 15px;
}

.courses-btn-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

canvas {
    margin-left: 50px;
    height: 320px;
    /* max-width: 700px; */
}

/* .btn-icon-1{
    margin-left:37px ;
}

.btn-icon-2{
    margin-left:20px;
} */

.sidebar-button-icon {
    margin-top: 8px;
}

/* .courses-btn{
    margin-right: 10px;
} */

/* .chatbot-span{
    font-size:2.0rem;
    cursor:pointer;
}

.chatbot-span:hover{
    text-decoration: underline;
    text-underline-offset: 0.2em;
} */

.sidebar-contentarea {
    display: flex;
    flex-direction: row;
}

.help-button-text {
    justify-content: flex-end;
    font-size: 1.8rem;
    font-weight: normal;
}

/* .nav-center{
    margin-left:15%;
} */