@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    width:100%;
} 

section {
    width: 100%;
    max-width: 440px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ebd461; /* Use a darker shade of grey */
    border: 1px solid #222222; /* Use a darker shade of black */
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5); /* Add a box-shadow to create a 3D effect */
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

/* a, a:visited {
    color: #fff;
} */

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.line a {
    color:white;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.9s ease-in-out;
  }
  
.fade-out {
    opacity: 0;
    transition: opacity 0.9s ease-in-out;
  }
  
  .left-img{
    margin-left:0;
    height:100vh;
    width:80%;
    overflow: hidden;
  }

  .register-container{
    display:flex;
  }

  .login-container{
    display:flex;
  }