/* *{
    margin:0;
    box-sizing:border-box;
    font-family: 'Times New Roman', Times, serif;
} */

.notes-container{
    margin-top:0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100vh;
    background-color:whitesmoke ;
}

.notes-container h2{
    font-size: 1.7rem;
    margin-bottom: 30px;
}

.notes-container form{
    border:2px solid black;
    width:60%;
    max-height:400px;
    background-color: #ebd461;
    border-radius:10px 10px 10px 10px;
}

.notes-container textarea{
    width:95%;
    margin-left: 23px;
}

.notes-container button{
    display:flex;
    justify-content: center;
    width:20%;
    margin-left: 40%;
}

.notes-container ul{
    margin-top:30px;
    font-size:1.6rem; 
}
