/* .header-nav {
    display: flex;
    margin-left: 3%;
    align-items: center;
    margin-top: 20px;
} */

.header-nav {
    /* margin-left: 5px; */
    width: 95%;
    display: flex;
    align-items: center;
    margin: auto;
    background-color: transparent;
    margin-top: 5px;
    margin-left: 2.5%;
    border-radius: 10px 10px 10px 10px;
    border: 2px solid rgba(0, 0, 0, 0.357);
    z-index: 20;
    /* height:120px; */
    font-size: 26px;
    white-space: nowrap;
}

.header-nav-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
}

.header-nav-logo {
    height: 80px;
    width: 80px;
    margin-right: 10px;
}

.header-nav-logo-h3 {
    font-size: 1.6rem;
    font-family: "Cursive";
    font-weight: 400;
}

.header-nav-center {
    display: flex;
    align-items: center;
    margin: auto;
    /* white-space: nowrap; */
    /* justify-content: space-between; */
    /* margin-left: 120px; */
}

/* .header-nav-center {
    margin-left: 15%;
} */

.header-nav-center span {
    color: black;
}

/* .header-nav-right {
    margin-left: 20%;
} */

/* .header-help-button {
    height: 70px;
    padding-top: 3px;
    margin-bottom: 20px;
} */

.header-help-button {
    text-decoration: none;
    background-color: #ebd461;
    display: flex;
    flex-direction: row;
    border: none;
    border-radius: 10px;
    width: 200px;
    align-items: center;
    margin-bottom: 20px;
    height: 70px;
    margin-right: 30px;
}

.header-help-button-text {
    font-weight: bold;
    font-size: 23px;
    margin-left: 13px;
    align-items: center;
    margin-right: auto;
}

.header-help-button:hover {
    background-color: #ebd461d5;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-underline-offset: 0;
}

/* .header-help-button span {
    font-size: 1.8rem;
} */

.header-chatbot-span {
    font-size: 2.0rem;
    cursor: pointer;
}

.header-chatbot-span:hover {
    text-decoration: underline;
    text-underline-offset: 0.2em;
}

li{
    display: flex;
    /* align-self: center; */
    margin-right: 10%;
    /* margin-left: 10%; */
    /* margin-right: 2.5%; */
    /* align-self: right;
    margin-left:50px;
    margin-top: 15px; */
}

li a{
    text-decoration: none;
    font-weight: normal;
    /* font-size: 190%; */
    color: black;
    /* margin-right: 30px; */
    transition: all 0.3s ease-in-out;
  }

/* li a:hover {
    border-bottom: 2px solid black;
    font-weight: bold;
  } */
