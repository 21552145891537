  /* * {
    font-family: "Cursive";
    color: black;
  } */
  
  .quii {
    background-color: white;
    width: 70%;
    height: 0%;
    border-radius: 15px;
    padding: 20px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
    margin-left:50px;
  }
  
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
    font-size: 22px;
  }
  
  .question-count span {
    font-size: 50px;
  }
  
  .question-text {
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .timer-text {
    background: rgb(240, 177, 61);
    padding: 100px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  button {
    width: 50%;
    font-size: 17px;
    color: black;
    background-color: white;
    border-radius: 7px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid black;
    cursor: pointer;
  }
  
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  button:hover {
    background-color: #9d9d99;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }

  .curriculum-cotainer{
    display:block;
    height:100vh;
  }

  .sidebar {
    margin-top:20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22%;
    padding: 20px;
    height: 75%;
    z-index: -1;
  }

  .content {
    border-left: 2px solid rgb(0, 0, 0); 
    width: 80%; /* set the width of the main content area */
    padding: 20px; /* add padding to the content area */
  }
  
  .content:border-left{
    height: 120%;
  }

  .sidebar a{
    width:100%;
  }
  
  .sidebar h2{
    margin-left: 90px;
  }
  
  .sidebar-btn {
    margin-bottom: 20px;
  }
  
  .sidebar-btn-1{
  cursor: pointer;
  overflow: visible;
  }
  
  .sidebar-btn-img{
    margin-left: 50px;
  }

  .button-text{
    display: flex;
    flex-direction: row;
  }
  
  .button-text pre{
    width:50%;
    text-align: center;
    overflow: hidden;
  }
  
  .sidebar span,pre {
  font-size:1.6rem;
  }
  
  .button {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
    width: 330px;
    height: 70px;
    text-align: left;
    color: black;
    background-color: #ebd461;
    border: none;
    border-radius: 10px;
    margin-bottom: 25px;
  }

  .sdiebar button{
    background-color: #ebd461;
  }
  
  .sidebar button:hover {
    cursor: pointer;
  }

  .cur-below-nav{
    display: flex;
  }
  
  .nav {
      margin-left: 5px;
      width: 95%;
      display: flex;
      align-items: center;
      background-color: transparent;
      margin-top: 5px;
      border-radius: 10px 10px 10px 10px;
      border: 2px solid rgba(0, 0, 0, 0.357);
      z-index: 20;
      height:120px;
    }
    
    nav button:hover {
      background-color: #ebd461d5;
      color: rgb(0, 0, 0);
      font-weight: bold;
    }

    .sidebar-quiz{
      display:flex;
      flex-direction: row;
    }
    